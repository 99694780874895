import React from "react";

export const SVGPreview = ({ URL, handleClick, name, index, backgrounds }) => (
  <div
    className={`card svg-preview-container ${backgrounds === `dark` && `dark`}`}
  >
    <div className="svg-preview">
      <img alt={name} src={URL} />
    </div>
    <div className="svg-preview-actions">
      <button className="convert-png" onClick={() => handleClick(URL)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            fill="currentColor"
            d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z"
          />
        </svg>
        PNG
      </button>
    </div>
  </div>
);
