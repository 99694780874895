import React from "react";
import { Formik } from "formik";
import { getConverted } from "../utils/getConverted";
import { Spinner } from "./Spinner";

export const ImageSpecsForm = ({ selectedSVG, handleModalClose }) => (
  <div className="image-specs-form">
    <Formik
      initialValues={{ width: ``, height: `` }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);

        try {
          const result = await getConverted(selectedSVG, values);
          console.log(result);
          setSubmitting(false);
        } catch (error) {
          console.error(error);
          setSubmitting(false);
        }

        setSubmitting(false);
        handleModalClose();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <label>Width:</label>
          <input
            type="number"
            name="width"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.width}
          />
          <label>Height:</label>
          <input
            type="number"
            name="height"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.height}
          />
          <button
            type="submit"
            className="button button-spinner mt-10"
            disabled={isSubmitting}
          >
            {!isSubmitting && <>Submit</>}
            {isSubmitting && <Spinner />}
          </button>
        </form>
      )}
    </Formik>
  </div>
);
