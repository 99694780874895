export const backgroundOptions = [
  {
    name: `light`,
    label: `Light`
  },
  {
    name: `dark`,
    label: `Dark`
  }
];

export const orientationOptions = [
  {
    name: `horizontal`,
    label: `Horizontal`
  },
  {
    name: `vertical`,
    label: `Vertical`
  }
];