import React, { useState } from "react";
import { ImageSpecsForm } from "./components/ImageSpecsForm";
import { Modal } from "./components/Modal";
import { RadioButtons } from "./components/RadioButtons";
import { VariantContainer } from "./components/VariantContainer";
import { backgroundOptions, orientationOptions } from "./shared/constants";
import "./styles.scss";

function App() {
  const [showModal, setShowModal] = useState(false);
  const [selectedSVG, setSelectedSVG] = useState(null);
  const [orientations, setOrientations] = useState("horizontal");
  const [backgrounds, setBackgrounds] = useState("light");
  const [selectedProperty, setSelectedProperty] = useState("regroove");

  let SVGs;
  if (selectedProperty === `regroove`) {
    SVGs = require("./data/regroove-logos.json");
  }
  if (selectedProperty === `navo`) {
    SVGs = require("./data/navo-logos.json");
  }
  if (selectedProperty === `shelterball`) {
    SVGs = require("./data/shelterball-logos.json");
  }
  if (selectedProperty === `rockstar-nights`) {
    SVGs = require("./data/rockstar-nights-logos.json");
  }

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleClick = SVG => {
    setSelectedSVG(SVG);
    setShowModal(true);
  };

  const handleOptionChange = (event, stateSetter) => {
    const { value } = event.target;
    stateSetter(value);
  };

  return (
    <div className={`app ${backgrounds}`}>
      <header className="header pt-20">
        <div className="container px-20">
          <h2>Logos</h2>
        </div>
      </header>
      <div className="container p-20">
        <button
          className="button mr-5"
          onClick={() => setSelectedProperty("regroove")}
        >
          Regroove
        </button>
        <button className="button mr-5" onClick={() => setSelectedProperty("navo")}>
          Navo
        </button>
        <button className="button mr-5" onClick={() => setSelectedProperty("shelterball")}>
          Shelterball
        </button>
        <button className="button" onClick={() => setSelectedProperty("rockstar-nights")}>
          Rockstar Nights
        </button>
      </div>

      <div className="container p-20 overflow-x-auto">
        <div className="d-flex controls">
          <RadioButtons
            stateValue={backgrounds}
            groupHeading="Backgrounds"
            groupName="backgrounds"
            options={backgroundOptions}
            changeHandler={event => handleOptionChange(event, setBackgrounds)}
          />
          <RadioButtons
            stateValue={orientations}
            groupHeading="Orientation"
            groupName="orientation"
            options={orientationOptions}
            changeHandler={event => handleOptionChange(event, setOrientations)}
          />
        </div>
      </div>

      {SVGs.map(({ id, name, items }) => (
        <VariantContainer
          variantName={name}
          variant={id}
          handleClick={SVG => handleClick(SVG)}
          items={items}
          backgrounds={backgrounds}
          orientation={orientations}
        />
      ))}

      <main>
        {showModal && (
          <Modal
            componentToDisplay={
              <ImageSpecsForm
                selectedSVG={selectedSVG}
                handleModalClose={() => handleModalClose()}
              />
            }
            modalTitle="Choose image dimensions"
            handleModalClose={() => handleModalClose()}
          />
        )}
      </main>
    </div>
  );
}

export default App;
