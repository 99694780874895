import React from "react";
import { SVGPreview } from "./SVGPreview";

export const VariantContainer = ({
  items,
  variantName,
  orientation,
  backgrounds,
  handleClick,
}) => (
  <div className="container p-20">
    <h3 className="heading-variant">{variantName}</h3>
    <div className="d-grid">
      {items.length !== 0 &&
        items
          .filter(item => item.backgrounds.includes(backgrounds))
          .filter(item => item.orientations.includes(orientation))
          .map((item, index) => (
            <SVGPreview
              backgrounds={backgrounds}
              index={index}
              key={item.name}
              {...item}
              handleClick={SVG => handleClick(SVG)}
            />
          ))}
    </div>
  </div>
);
