import React from "react";

const RadioButton = ({ name, label, groupName, changeHandler, stateValue }) => (
  <>
    <input
      type="radio"
      className="radio-button"
      name={groupName}
      id={`${groupName}--${name}`}
      value={name}
      checked={stateValue === name}
      onChange={changeHandler}
    />
    <label htmlFor={`${groupName}--${name}`}>{label}</label>
  </>
);

export const RadioButtons = ({
  groupHeading,
  groupName,
  changeHandler,
  options,
  stateValue,
}) => (
  <div className="radio-group">
    <div className="radio-group-heading">{groupHeading}</div>
    <div className="radio-button-group">
      {options.map(option => (
        <RadioButton
          key={option.name}
          {...option}
          groupName={groupName}
          changeHandler={changeHandler}
          stateValue={stateValue}
        />
      ))}
    </div>
  </div>
);
