import { saveAs } from "file-saver";

export function getConverted(SVG, values) {
  return new Promise((resolve, reject) => {
    const svg_image_id = document.getElementById("svg_image_id");
    console.log(SVG)

    fetch('https://cors-anywhere.herokuapp.com/' + SVG)
      .then(r => r.blob())
      .then(async b => {

        function updateObjectSVG() {
          return new Promise((resolve, reject) => {
            svg_image_id.addEventListener('load', e => {
              URL.revokeObjectURL(svg_image_id.data);
              const SVG = svg_image_id.contentDocument.querySelector("svg");
              resolve(SVG);
            });
            svg_image_id.data = URL.createObjectURL(b);
          });
        }

        const SVGObject = await updateObjectSVG();
        const serializer = new XMLSerializer();
        const SVG = serializer.serializeToString(SVGObject);

        const { width, height } = values;

        const data = {
          SVG,
          width,
          height,
        }

        const response = await fetch(process.env.REACT_APP_API_GET_CONVERTED, {
          mode: `cors`,
          method: `POST`,
          headers: {
            "Content-Type": `application/json`
          },
          body: JSON.stringify(data)
        });

        const blob = await response.blob();
        const img = URL.createObjectURL(blob);

        saveAs(img, `regroove-logo${width && `-w-`}${width && width}${height && `-h-`}${height && height}.png`);
        resolve();
      });
  });

}